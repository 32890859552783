body {
  background: #FFF;
  color: #0E0E0E;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  -webkit-font-aliasing: antialiased;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
}

a {
  color: blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

p {
  line-height: 1.5em;
}

ul {
  line-height: 1.5em;
}

span.help {
    display: inline-block;
    color: #777;
    font-size: 14px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    background-color: #EEE;
    border-radius: 50px;
    font-weight: normal;
    margin-left: 5px;
}

.container {
  max-width: 960px;
  min-height: 100vh;
  margin: 0px auto;
  padding: 20px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.narrow-content {
    max-width: 600px;
    margin: 0px auto;
}

.narrow-content img {
    max-width: 100%;
}

header {
  display: flex;
  border-bottom: 1px solid #DDD;
  align-items: flex-end;
}

header h1 {
    flex: 1;
}

header h1 .alpine {
    display: inline-block;
    font-weight: normal;
    font-size: 14px;
    margin-left: 15px;
}

header ul {
    list-style: none;
    display: flex;
}

header li {
    padding: 5px 10px;
}


ul.members {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

ul.members li {
    margin: 0px 0px 20px 0px;
    padding: 0px;
    display: flex;
    align-items: flex-start;
    text-align: left;
}

ul.members li:nth-last-child(1) {
    margin-bottom: 0px;
}

ul.members img {
    width: 52px;
    height: 52px;
    border-radius: 24px; 
    display: block;
    margin-right: 15px;
}

ul.members a.handle {
    font-size: 24px;
    margin-right: 5px;
    margin-bottom: 8px;
    color: #0E0E0E;
}

ul.members .date {
    color: #93999b;
    font-size: 14px;
}

ul.members .status {
    color: #93999b;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 0px;
}

ul.members .actions {
    font-size: 14px;
    margin-top: 5px;
}

ul.members .meta {
    line-height: 1em;
    display: flex;
    flex-direction: column;
}

.list-stats {
    text-align: center;
    font-size: 24px;
    margin-bottom: 5px;
}

.lists {
    display: flex;
}

.list {
    flex: 1;
    padding: 0px 10px;
    text-align: center;
}

.tcr-info {
    color: #93999b;
    font-size: 12px;
}

span.code {
    font-family: Monospace;
}

.spacer {
    flex: 1;
}
